<template>
  <div id="top">
    <template v-if="gettingData">
      <div class="w-screen h-screen bg-white flex items-center justify-center">
        <div>
          <img src="../../assets/Legal-Naija-Logo.jpeg" />
        </div>
      </div>
    </template>
    <template v-else>
      <navbar />
      <div class="w-10/12 mx-auto container md:mt-40 mt-28">
        <div class="border-gray-200 border bg-white rounded shadow">
          <div class="flex md:flex-row flex-col items-start justify-start">
            <div class="md:w-3/12 w-full h-full">
              <img :src="user.avatar" class="md:h-auto w-full border-none" />
            </div>
            <div class="md:ml-8 md:w-9/12 p-3 py-4 px-5">
              <h2 class="md:text-3xl text-2xl font-bold text-black mb-2">
                {{ user.firstName }} {{ user.lastName }}
              </h2>
              <div class="flex items-center justify-start mb-2">
                <img
                  src="../../assets/svg/building.svg"
                  alt="LawFirm"
                  title="Law Firm"
                  class="mr-3 md:w-6 w-5"
                />
                <h3
                  class="md:text-lg text-base font-semibold text-gray-700 mb-0"
                >
                  {{ user.lawFirm }}
                </h3>
              </div>

              <div class="flex items-center justify-start mb-2">
                <img
                  src="../../assets/svg/location.svg"
                  alt="businessAddress"
                  title="Business Address"
                  class="mr-3 md:w-6 w-5"
                />
                <p class="text-sm text-gray-700 mb-0">
                  {{ user.businessAddress }}
                </p>
              </div>

              <div class="flex items-baseline flex-wrap mb-4">
                <div class="flex mr-3 items-center">
                  <img
                    src="../../assets/svg/email.svg"
                    alt="email"
                    title="email"
                    class="mr-3 w-5"
                  />
                  <p class="mb-0 truncate">{{ user.email }}</p>
                </div>
                <div class="flex mr-3 items-center">
                  <img
                    src="../../assets/svg/phone.svg"
                    alt="email"
                    title="email"
                    class="mr-3 w-5"
                  />
                  <p class="mb-0">{{ user.phone }}</p>
                </div>
              </div>
              <div class="flex flex-wrap">
                <span
                  v-for="practice in user.areasOfPractice"
                  :key="practice._id"
                  :style="{
                    fontSize: '12px',
                  }"
                  class="
                    text-black text-opacity-75
                    bg-gray-200
                    rounded-full
                    px-2
                    py-1
                    mx-1
                    my-1
                    capitalize
                  "
                  >{{ practice.practice.title }}</span
                >
              </div>
              <div class="flex mt-5 w-full">
                <!-- <a
                target="_blank"
                class="bg-primary mr-3 hover:text-white text-center text-white font-semibold text-sm px-4 md:w-28 w-20 py-2 rounded-full hover:shadow-md hover:bg-primary-dark duration-150 transition-colors"
                :href="`mailto:${user.email}`"
                >Email</a
              > -->
                <a
                  class="
                    bg-primary
                    hover:text-white
                    text-center text-white
                    font-semibold
                    text-sm
                    px-4
                    md:w-28
                    w-20
                    py-2
                    rounded-full
                    hover:shadow-md
                    hover:bg-primary-dark
                    duration-150
                    transition-colors
                  "
                  :href="`tel:${user.phone}`"
                  >Call</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="border-t-4 border-primary-dark bg-white py-7">
          <div class="md:w-6/12 w-11/12 mx-auto container">
            <h2 class="font-bold text-2xl">Send a message</h2>
            <a-form-model
              ref="messageForm"
              :rules="rules"
              :model="form"
              layout="vertical"
              class="form"
            >
              <a-form-model-item
                has-feedback
                label="Your Name"
                prop="senderName"
              >
                <a-input
                  v-model="form.senderName"
                  :disabled="sending"
                  placeholder="Enter your name"
                >
                </a-input>
              </a-form-model-item>
              <a-form-model-item
                has-feedback
                label="Your Email"
                prop="senderEmail"
              >
                <a-input
                  v-model="form.senderEmail"
                  :disabled="sending"
                  placeholder="Enter your e-mail"
                >
                </a-input>
              </a-form-model-item>
              <a-form-model-item
                has-feedback
                label="Your Phone Number (Optional)"
                prop="senderPhone"
              >
                <a-input
                  v-model="form.senderPhone"
                  :disabled="sending"
                  placeholder="Enter your phone number"
                >
                </a-input>
              </a-form-model-item>
              <a-form-model-item
                has-feedback
                label="Your Message"
                prop="message"
              >
                <a-textarea
                  v-model="form.message"
                  auto-size
                  :disabled="sending"
                  placeholder="Type your message here"
                  :rows="4"
                >
                </a-textarea>
              </a-form-model-item>
            </a-form-model>
            <div class="flex items-start justify-end">
              <a-button
                :loading="sending"
                type="primary"
                block
                @click="sendMessageValid"
                >Send Message</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import navbar from '../../components/navbar.vue'
import userApi from '../../api/user'

export default {
  components: { navbar },
  data() {
    return {
      user: {},
      name: '',
      userId: '',
      form: {},
      sending: false,
      gettingData: false,
      rules: {
        senderName: [
          {
            message: 'Please enter your name',
            required: true,
          },
        ],
        senderEmail: [
          {
            pattern:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            required: true,
            message: 'Enter a valid email address',
            trigger: 'blur',
          },
        ],
        message: [
          {
            message: 'Please type for message',
            required: true,
          },
        ],
      },
    }
  },
  created() {
    this.userId = this.$route.params.id
    this.name = this.$route.params.name
    this.getUserData()
  },
  mounted() {
    this.$scrollTo('#top', 300, {
      offset: -120,
    })
  },
  methods: {
    async getUserData() {
      this.gettingData = true
      const req = await userApi(this.axios).getUpdatedProfile(this.userId, {
        populate: ['areasOfPractice.practice'],
      })
      this.user = req.data
      this.gettingData = false
    },
    async sendMessage() {
      this.sending = true
      const req = await userApi(this.axios).sendMessage({
        senderName: this.form.senderName,
        lawyerId: this.userId,
        senderEmail: this.form.senderEmail,
        message: this.form.message,
        senderPhone: this.form.senderPhone,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to send message',
          description: req.message,
        })
        this.sending = false
        return
      }
      this.$notification.success({
        message: 'Message sent!',
        description: req.message,
      })
      this.sending = false
      this.form = {}
    },
    sendMessageValid() {
      this.$refs.messageForm.validate((valid) => {
        if (valid) {
          this.sendMessage()
        } else {
          return false
        }
      })
    },
  },
}
</script>
